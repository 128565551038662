import React, { useState } from 'react'
import  Grid from "@material-ui/core/Grid"
import { AllOptions } from './../components/allOptions'
import { FloorplanInformation } from './../components/floorplanInformation'
import { FloorplanImage } from './../components/floorplanImage'
import { Switches } from './../components/switch'
import useDimensions from 'react-cool-dimensions'

export const Desktop = (props) => {

    const { 
        floorplanImages, 
        selectedFloorplanOption, 
        selectFloorplanImage, 
        selectElevationImage, 
        viewFloorplan , 
        setViewFloorplan, 
        floorplanOptions , 
        setSelectedFloorplanOption,
        setFloorplanPrice,
        floorplanInformation,
        floorplanPrice
    } = props

    const [stickyImg, setStickyImg] = useState(false)

    /* Ref to container of image, grabbing the width of the container which we use to calculate the height of the image
       then we add 50px to that so when elevation is shown, it compensates for the extra height of the elevation bar. Set a min-height 
       so when we transition between floorplan and elevation images, the switch button doesn't move and down  */
    const { ref, width } = useDimensions();
    /* Aspect ratio of the image - to get ratio use ratio = width / height then use height = width / ratio to calculate height*/
    const aspectRatio = 1.67;
    /* Calculates the height everytime the image size changes */
    const calculatedHeight = (width / aspectRatio) + 50;

    /*  Ref to the Custom Options Column, so we have access to all it's dimensions.
        Checking if the column is taller than the browser viewport, if it is then make the image sticky and if not, don't make it sticky */
    const options = useDimensions({

        onResize: ({height}) => {

            if(height > window.innerHeight && stickyImg === false ) { setStickyImg(true) } 
            else if(height < window.innerHeight && stickyImg === true) { setStickyImg(false) }
        
        }

    })


    return (
        <Grid container spacing={5} justify="center" direction="row" alignItems="stretch" className="mobile-hidden" >

                <Grid item md={8}>
                    <div id={ stickyImg === true ? 'sticky' : 'not-sticky' } >
                        <div className="stickable stickable-container" ref={ref} style={{ minHeight: `${calculatedHeight}px` }}>
                            <FloorplanImage
                                floorplanImages={floorplanImages}
                                selectedFloorplanOption={selectedFloorplanOption}
                                selectFloorplanImage={selectFloorplanImage}
                                selectElevationImage={selectElevationImage}
                                viewFloorplan={viewFloorplan}
                            />

                            <Switches
                                onChange={() => setViewFloorplan(viewFloorplan => !viewFloorplan)}
                                viewFloorplan={viewFloorplan}
                            />
                        </div>
                    </div>
                </Grid>

                <Grid item md={4} ref={options.ref}>
                    <AllOptions
                        floorplanOptions={floorplanOptions}
                        selectedFloorplanOption={selectedFloorplanOption}
                        onClick={(event) => {
                            setSelectedFloorplanOption(event.target.dataset.value) 
                            setFloorplanPrice(event.target.dataset.price)
                        }}
                    />
                    <FloorplanInformation
                        floorplanInformation={floorplanInformation}
                        floorplanPrice={floorplanPrice}
                    />
                </Grid>

        </Grid>
    )
}
