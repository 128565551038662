import React, { useEffect, useRef } from 'react'
import { PriceToString } from './../logic/priceToString'
/* import CountUp from 'react-countup'
 */
export const FloorplanInformation = (props) => {

    const { floorplanInformation, floorplanPrice } = props
/*     const price = floorplanInformation.price + parseInt(floorplanPrice)
 *//*     const prevPrice = usePrevious(price)
 */
    return (
       <div className="floorplan-information">

            <div className="price-wrapper">
                <p>Price</p>
                <p>{PriceToString(floorplanInformation.price)}</p>
                {/* <CountUp
                    start={prevPrice}
                    end={price}
                    separator=","
                    prefix="$"
                    duration={.5} 
                /> */}
            </div>

            <h3>{floorplanInformation.name}</h3>

            <div className="floorplan-description">
                <p>{floorplanInformation.rooms}</p>
                <p>|</p>
                <p>{floorplanInformation.baths}</p>
                <p>|</p>
                <p>{floorplanInformation.squareFootage}</p>
                <p>|</p>
                <p>{floorplanInformation.dimension}</p>
            </div>

            <div className="button-wrapper">
                <a href={floorplanInformation.pdf} alt="download floorplan pdf" download id="design-btn">Download all option details</a>
            </div>
       </div> 
    )

}

/* function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    })

    return ref.current;
} */