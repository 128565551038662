import React, { useState, useRef } from 'react'
import { ElevationBar } from './elevationBar'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

export const FloorplanImage = React.forwardRef((props, ref) => {

    const { floorplanImages, selectedFloorplanOption, selectFloorplanImage, selectElevationImage, viewFloorplan } = props
    const [selectedElevation, setSelectedElevation] = useState('elevationFront')
    const nodeRef = useRef(null)

    return (
        <>
            <SwitchTransition>
                <CSSTransition
                    key={viewFloorplan}
                    timeout={400}
                    classNames="fade"
                    nodeRef={nodeRef}
                >
                    {
                        viewFloorplan === true ? 
                            <>
                                <div ref={nodeRef} >
                                    <img src={selectFloorplanImage(floorplanImages, selectedFloorplanOption)} ref={ref}  alt="floorplan" width="100%"/>
                                </div>
                            </>
                            :
                            <div ref={nodeRef}>
                                <ElevationBar
                                    onClick={(event) => setSelectedElevation(event.target.dataset.value)}
                                    selectedElevation={selectedElevation}
                                />
                                <img src={selectElevationImage(floorplanImages, selectedFloorplanOption, selectedElevation)} ref={ref} alt="floorplan" width="100%"/>
                            </div>
                    }
                </CSSTransition>
            </SwitchTransition>
        </>
    )

})