import { Floorplan } from './layout/floorplan'
import './App.scss';

function App() {
  return (
    <Floorplan/>
  );
}

export default App;
