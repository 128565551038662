import React from 'react'

export const ElevationBar = (props) => {

    const { onClick, selectedElevation } = props

    return (
        <div className="elevation-bar">
            <button className={selectedElevation === "elevationFront" ? "elevation-bar-item active" : "elevation-bar-item"} data-value="elevationFront" onClick={onClick}>Front</button>
            <button className={selectedElevation === "elevationLeft" ? "elevation-bar-item active" : "elevation-bar-item"} data-value="elevationLeft" onClick={onClick}>Left</button>
            <button className={selectedElevation === "elevationRear" ? "elevation-bar-item active" : "elevation-bar-item"} data-value="elevationRear" onClick={onClick}>Rear</button>
            <button className={selectedElevation === "elevationRight" ? "elevation-bar-item active" : "elevation-bar-item"} data-value="elevationRight" onClick={onClick}>Right</button>
            <span className={`grey-bar ${selectedElevation}`}></span>
        </div>
    )

}