import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

var firebaseConfig = {
    apiKey: "AIzaSyBcE_iqjTwxYSDPLdg1Kw2BZO-FrIpZ__Q",
    authDomain: "natomas-app.firebaseapp.com",
    databaseURL: "https://natomas-app-default-rtdb.firebaseio.com",
    projectId: "natomas-app",
    storageBucket: "natomas-app.appspot.com",
    messagingSenderId: "665815670521",
    appId: "1:665815670521:web:ae438bebf26a1a0f274c28",
    measurementId: "G-THNES7HYMK"
  };

  export const firebaseApp = firebase.initializeApp(firebaseConfig)
  export const firestore = firebase.firestore()
  export const storage = firebaseApp.storage()