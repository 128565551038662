import React from 'react'
import Switch from '@material-ui/core/Switch'

export const Switches = (props) => {

    const { onChange, viewFloorplan } = props

    return (
        <div className="switch-wrapper">
            <Switch inputProps={{ 'aria-label': 'primary checkbox' }} onChange={onChange}/>
            <div className="text-wrapper">
                <p className={ viewFloorplan === true ? 'white' : '' } >Floor plan</p>
                <p className={ viewFloorplan === true ? '' : 'white' } >Elevation</p>
            </div>
        </div>
    )

}