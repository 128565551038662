import React from 'react'
import  Grid from "@material-ui/core/Grid"

export const Mobile = (props) => {
    const {
        floorplanImages,
        selectFloorplanImage,
        setToggleDrawer
    } = props

    const openDrawer = () => {

        setToggleDrawer(true)

        /* Prevents the background from scrolling by grabbing where the root element is positioned in document and freezing it there */
        const body = document.body;
        /* Grabs where the body is in position and then freezes it by setting the body to fixed and overflow to hidden to prevent it scrolling */
        body.style.top = `-${window.scrollY}px`;
        body.style.position = 'fixed'
        body.style.overflow = "hidden"
    }

    return (
        <Grid container spacing={5} justify="center" direction="row" alignItems="center" className="desktop-hidden">

            {/* On mobile, it's always the baseplan that is shown as a static image */}
            <Grid item>
                <img src={selectFloorplanImage(floorplanImages, "baseplan")} id="floorplan-sticky" alt="floorplan" width="100%"/>
            </Grid>

            <Grid item>

                <button className="white-btn" onClick={openDrawer}>Explore customized options</button>     

            </Grid>

        </Grid>
    )
}