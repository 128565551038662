import React from 'react'
/* import { PriceToString } from './../logic/priceToString'
 */
export const Option = (props) => {

    const { optionClass, optionTitle, optionPrice, optionValue, onClick  } = props

    //If option price is 0, use string
    return (
        <div className={`option-wrapper`} onClick={onClick} data-value={optionValue} data-price={optionPrice}>
            <button className={`option-button ${optionClass}`} >
            </button>
            <div className="option-info">
                <p>{optionTitle}</p>
                {/* <p>{PriceToString(optionPrice)}</p> */}
            </div>
        </div>
    )

}