export const PriceToString = (number) => {

    if(number === 0 ) {
        return "Included"
    } else {
    
        const numLength = number.toString().length;
        let priceArray;
        let numBeforeComma;
        let numAfterComma;
        let priceTogether;

        switch (numLength) {
            case 6: 
                priceArray = number.toString().split('');
                numBeforeComma = priceArray.slice(0,3).join('');
                numAfterComma = priceArray.slice(3, priceArray.length).join('')
                priceTogether = `$${numBeforeComma},${numAfterComma}`
                return priceTogether
            case 5:
                priceArray = number.toString().split('');
                numBeforeComma = priceArray.slice(0,2).join('');
                numAfterComma = priceArray.slice(2, priceArray.length).join('')
                priceTogether = `$${numBeforeComma},${numAfterComma}`
                return priceTogether

            case 4:
                priceArray = number.toString().split('');
                numBeforeComma = priceArray.slice(0,1).join('');
                numAfterComma = priceArray.slice(1, priceArray.length).join('')
                priceTogether = `$${numBeforeComma},${numAfterComma}`
                return priceTogether

            case 3: return number;
            default: return number;
  
        }
    }
}