import React, {useEffect , useState } from 'react'
import { getFloorplanImages, getFloorplanInformation, getFloorplanOptions , selectFloorplanImage, selectElevationImage } from './../logic/floorplanCatalogApi'
import { Desktop } from './desktop'
import { Mobile } from './mobile'
import { PopUp } from './popup'
import 'firebase/firestore'

export const Floorplan = () => {
    
    const [floorplanImages, setFloorplanImages] =  useState(null)
    const [floorplanInformation, setFloorplanInformation] = useState(null)
    const [floorplanOptions, setFloorplanOptions] = useState(null)
    const [selectedFloorplanOption, setSelectedFloorplanOption] = useState('baseplan')
    const [viewFloorplan, setViewFloorplan] = useState(true)
    const [floorplanPrice, setFloorplanPrice] = useState(0)
    const [toggleDrawer, setToggleDrawer] = useState(false)

    useEffect(() =>  {

        async function LoadData() {
            const imageData = await getFloorplanImages()
            const informationData = await getFloorplanInformation()
            const optionsData = await getFloorplanOptions()
            setFloorplanImages(imageData)
            setFloorplanInformation(informationData)
            setFloorplanOptions(optionsData)
        }

        LoadData()

    },[])

    return (
        <>
            {
                floorplanImages === null || floorplanInformation === null || floorplanOptions === null ? 
                <p>Loading...</p>  
                :
                <> 

                    <div className="app-container">
                    
                     <Desktop
                        floorplanImages={floorplanImages}
                        selectedFloorplanOption={selectedFloorplanOption}
                        selectFloorplanImage={selectFloorplanImage}
                        selectElevationImage={selectElevationImage}
                        viewFloorplan={viewFloorplan}
                        setViewFloorplan={setViewFloorplan}
                        floorplanOptions={floorplanOptions}
                        setSelectedFloorplanOption={setSelectedFloorplanOption}
                        setFloorplanPrice={setFloorplanPrice}
                        floorplanInformation={floorplanInformation}
                        floorplanPrice={floorplanPrice}
                    />

                    <Mobile
                        floorplanImages={floorplanImages}
                        selectFloorplanImage={selectFloorplanImage}
                        setToggleDrawer={setToggleDrawer}
                    />

                    </div>

                    <PopUp
                        toggleDrawer={toggleDrawer}
                        setToggleDrawer={setToggleDrawer}
                        floorplanImages={floorplanImages}
                        selectedFloorplanOption={selectedFloorplanOption}
                        selectFloorplanImage={selectFloorplanImage}
                        selectElevationImage={selectElevationImage}
                        viewFloorplan={viewFloorplan}
                        setViewFloorplan={setViewFloorplan}
                        floorplanOptions={floorplanOptions}
                        setSelectedFloorplanOption={setSelectedFloorplanOption}
                        setFloorplanPrice={setFloorplanPrice}
                        floorplanInformation={floorplanInformation}
                        floorplanPrice={floorplanPrice}
                    />
                    
                                
                </> 

            
            }
        </>
    )
}
