import React from 'react'
import  Grid from "@material-ui/core/Grid"
import Drawer from '@material-ui/core/Drawer';
import { AllOptions } from './../components/allOptions'
import { FloorplanInformation } from './../components/floorplanInformation'
import { FloorplanImage } from './../components/floorplanImage'
import { Switches } from './../components/switch'
import { CloseButton } from './../components/closeButton'
import useDimensions from 'react-cool-dimensions'


export const PopUp = (props) => {

    const {

        toggleDrawer,
        setToggleDrawer,
        floorplanImages,
        selectedFloorplanOption,
        selectFloorplanImage,
        selectElevationImage,
        viewFloorplan,
        setViewFloorplan,
        floorplanOptions,
        setSelectedFloorplanOption,
        setFloorplanPrice,
        floorplanInformation,
        floorplanPrice

    } = props

    const closeDrawer = () => {

        setToggleDrawer(false)

        const body = document.body;
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        body.style.overflow = 'auto'
        window.scrollTo(0, parseInt(scrollY || '0') * -1);

    }

    /* Ref to container of image, grabbing the width of the container which we use to calculate the height of the image
       then we add 50px to that so when elevation is shown, it compensates for the extra height of the elevation bar. Set a min-height 
       so when we transition between floorplan and elevation images, the switch button doesn't move and down  */
       const { ref, width } = useDimensions();
       /* Aspect ratio of the image - to get ratio use ratio = width / height then use height = width / ratio to calculate height*/
       const aspectRatio = 1.67;
       /* Calculates the height everytime the image size changes */
       const calculatedHeight = (width / aspectRatio) + 170;

       /* Subtracts 50 for the extra margin added after */

       const subtractMargin = (calculatedHeight - 50);


    return (
        <Drawer
            variant="persistent"
            anchor="bottom"
            open={toggleDrawer}
        >
            <Grid container spacing={5} justify="center" direction="row" alignItems="center"  style={{ minHeight: `${calculatedHeight}px`}}>
                
                <Grid item ref={ref}  style={{ minHeight: `${subtractMargin}px`}}>
                    <CloseButton
                        onClick={closeDrawer}
                    />
                    <FloorplanImage
                        floorplanImages={floorplanImages}
                        selectedFloorplanOption={selectedFloorplanOption}
                        selectFloorplanImage={selectFloorplanImage}
                        selectElevationImage={selectElevationImage}
                        viewFloorplan={viewFloorplan}
                    />

                    <Switches
                        onChange={ () => setViewFloorplan(viewFloorplan => !viewFloorplan)}
                        viewFloorplan={viewFloorplan}

                    />

                </Grid>
                
            </Grid>

            <Grid container spacing={5} justify="center" direction="row" alignItems="center">
                <Grid item >
                    <AllOptions
                        floorplanOptions={floorplanOptions}
                        selectedFloorplanOption={selectedFloorplanOption}
                        onClick={(event) => {
                            setSelectedFloorplanOption(event.target.dataset.value) 
                            setFloorplanPrice(event.target.dataset.price)
                        }}
                    />
                    <FloorplanInformation
                        floorplanInformation={floorplanInformation}
                        floorplanPrice={floorplanPrice}
                    />
                </Grid>
            </Grid>
        </Drawer>
    )
}