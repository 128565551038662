import React from 'react'
import { Option } from './option'

export const AllOptions = (props) => {

    const { floorplanOptions, selectedFloorplanOption, onClick } = props

    return (
        <>
            <h2 className="title">Customization Options</h2>
                {    floorplanOptions.map(item => {
                            return (
                                    <Option
                                        optionClass={item.data.floorplanImageID === selectedFloorplanOption ? 'active' : 'not-active'}
                                        onClick={onClick}
                                        optionValue={item.data.floorplanImageID}
                                        optionTitle={item.data.title}
                                        optionPrice={item.data.price}
                                        key={item.id}
                                    />
                            )
                    })
                }

        </>
    )

}