import { firestore } from './../firebase'

const floorplanCatalog = firestore.collection("floorplanCatalog")
const url = window.location.href;

const unit = () => {

    if(url.includes('/adu-unit-floor-plans/450/')) 
        return "450"
    else if(url.includes('/adu-unit-floor-plans/550/'))
        return "550"
    else if(url.includes('/adu-unit-floor-plans/650a/'))
        return "650a"
    else if(url.includes('/adu-unit-floor-plans/650b/'))
        return "650b"
    else if(url.includes('/adu-unit-floor-plans/750/'))
        return "750"
    else if(url.includes('/adu-unit-floor-plans/800/'))
        return "800"
    else if(url.includes('/adu-unit-floor-plans/1000a/'))
        return "1000a"
    else if(url.includes('/adu-unit-floor-plans/1000b/'))
        return "1000b"
    else if(url.includes('/adu-unit-floor-plans/1200a/'))
        return "1200a"
    else if(url.includes('/adu-unit-floor-plans/1200b/'))
        return "1200b"
    else return "450"
    
}

export async function getFloorplanImages() {
    const response = floorplanCatalog.doc(unit()).collection('floorplanImages')
    const data = await response.get()
    let dataArray = [];

    //loop through the docs in the collection, grab there ID's and data, save into an object and push that object into the dataArray array 
    data.docs.forEach(item => {
        let dataObject = {};
        dataObject.id = item.id;
        dataObject.data = item.data()
        dataArray.push(dataObject)
       
    })

    return dataArray
}

export async function getFloorplanInformation() {

    const response = floorplanCatalog.doc(unit()).collection('floorplanInformation').doc('information')
    const data = await response.get().then((data) => { return data.data()})

    return data
}

export async function getFloorplanOptions() {
    const response = floorplanCatalog.doc(unit()).collection('floorplanOptions')
    const data = await response.get()
    let dataArray = [];

    //loop through the docs in the collection, grab there ID's and data, save into an object and push that object into the dataArray array 
    data.docs.forEach(item => {
        let dataObject = {};
        dataObject.id = item.id;
        dataObject.data = item.data()
        dataArray.push(dataObject)
    })
    
    //create new instance of data that is sorted from Highest price to lowest
    //If they have same price, it will be returned in alphabetical order
    const sortedData = [...dataArray].sort((a,b) => {
        
        //For prices that are the same, order them from Z -> A (reverse alphabetical order), so we can have baseplan be at the very bottom of list
        if(b.data.price === a.data.price) {

            let comparison = 0;

            if(a.id < b.id) { comparison = 1 }
            else if( a.id > b.id ) { comparison = -1}

            return comparison
        }

        return b.data.price - a.data.price
    })

    return sortedData
}

//goes through the floorplanImages data passed through, finding the selectedFloorplanOption, and gets the URL for the floorplan
export function selectFloorplanImage(floorplanImages, selectedFloorplanOption) {

    const floorplanImage = floorplanImages.find(item => item.id === selectedFloorplanOption).data.floorplanImage
    return floorplanImage

}

export function selectElevationImage(floorplanImages, selectedFloorplanOption, selectedElevation) {

    const elevationImage = floorplanImages.find(item => item.id === selectedFloorplanOption).data[`${selectedElevation}`]
    return elevationImage

}
 